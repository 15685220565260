import React from 'react';
import {Container } from 'react-bootstrap';

const Contact = () => {
return (
	<div>
	<Container className="containerPadding">

	<h1 className="headerStyle">Contact Us</h1>
	<hr className="tsLine" />
	<p>More to come.</p>


	</Container>
	</div>
);
};

export default Contact;
